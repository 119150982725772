import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import styles from './blog.module.css'

export default class Blog extends React.Component {

  renderArticleSummary = (post) => {
    return (
      <div className={styles.blogPost} key={post.frontmatter.slug}>
        <h2><Link to={post.frontmatter.slug}>{post.frontmatter.title}</Link></h2>
        <div className={styles.date}>Added <span>{post.frontmatter.date}</span></div>
        <div className={styles.blogDescription}>
          <p>{post.frontmatter.description}</p>
        </div>
      </div>
    )
  }

  render() {
    const posts = this.props.data.blogPosts ? this.props.data.blogPosts.edges: []

    return (
      <Layout>
        <Helmet
          title={'Blog - Remote Jobs Club: Work from Anywhere'}
        >
        </Helmet>
        <div className={styles.root}>
          <h1>Our blog <span role="img">✍️</span></h1>
          <div className={styles.blogList}>
            {posts.map(item => this.renderArticleSummary(item.node))}
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query BlogPostsQuery {

    blogPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          type: { eq: "blogPost" }
          draft: { eq: false }
        }
      }
    ){
      edges {
        node {
          frontmatter {
            title
            slug
            type
            draft
            description
            date(formatString: "MMMM DD, YYYY")
          }
          html
        }
      }
    }

  }
`;
